import { Controller } from "stimulus";

import Highcharts from 'highcharts';

export default class extends Controller {
  static values = {
    chart: Object
  }
  connect() {
    this.renderChart();
  }
  async renderChart() {
    const data = JSON.parse(this.element.dataset.index).data
    console.log(_.pick(data, 'd'))
    Highcharts.chart(this.element, {
      credits: false,
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: _.pluck(data, 'd')
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Earnings ($)'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:  '<td style="padding:0"><b>$ {point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        showInLegend: false,
        color: '#7C6EE4',
        data:  _.pluck(data, 'earnings')
    
      }]
    });
  }
  disconnect() {
    if(this.element) {
      console.log(this.element)
    }
  }
}
