import { Controller } from "stimulus"
import { useResize } from "stimulus-use";
import calculateAvg from "../lib/calculateAvg";

export default class extends Controller {
  static targets = ["source"]
  static values = {url: String}

  connect() {
    this.renderChart();
  }

  resize({ height, width }) {
    this.svg.attr("width", width);
  }

  async renderChart() {
    var chart = new CanvasJS.Chart(this.element, {
        animationEnabled: true,
        axisX: {
          title: "Episode Release Date",
          labelAngle: -90,
          labelFontSize: 12,
          titleFontSize: 12
        },
        axisY:{
          title: "Episode Downloads at Day 30",
          titleFontSize: 15,
          minimum: 0,
          labelFontSize: 12,
          titleFontSize: 12
        },
        toolTip: {
          contentFormatter: (e) => {
            var content = " ";
            content += e.entries[0].dataPoint.episode_name;
            content += "<br>";
            content += e.entries[0].dataSeries.name + ": "  + e.entries[0].dataPoint.y;
            content += "<br>";
            content += "Total: " + e.entries[0].dataPoint.total_y;
            return content;
          }
        },
        data: await this.generateData()
    });
    chart.render();
  }

  async generateData() {
    var data = await fetch(this.urlValue).then(async (data) => {
      var rawData = await data.json();

      var baseActuals     = rawData.base_30d_actuals;
      var baseActualsProj = rawData.base_30d_actuals_proj;
      var acqActuals      = rawData.acqs_30d_actuals;

      var stackedColumns = [];

      // Push the Base Actuals
      stackedColumns.push({
        type: "stackedColumn",
        color: "#3D85C6",
        showInLegend: true,
        name: "Baseline DLs",
        dataPoints: baseActuals
      });

      // Push the Estimated
      stackedColumns.push({
        type: "stackedColumn",
        color: "#9FC5E8",
        showInLegend: true,
        name: "Projected DLs",
        dataPoints: baseActualsProj
      });

      // Push the Conversions
      stackedColumns.push({
        type: "stackedColumn",
        color: "#69A84F",
        showInLegend: true,
        name: "Acquired DLs",
        dataPoints: acqActuals
      })

      return stackedColumns;
    });

    return data;
  }
}
