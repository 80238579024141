import { Controller } from "stimulus";
import Glide from '@glidejs/glide';
import noUiSlider from 'nouislider';

export default class extends Controller {
  static values = {
    clickPipsSlider: Object
  }
  connect() {
    new Glide('.glide',
      {
        type: 'slider',
        perView: 3,
        rewind: false,
        breakpoints: {
          800: {
            perView: 2
          },
          480: {
            perView: 1
          }
        }
      }
    ).mount();
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    const els = document.getElementById("earnings");
    this.clickPipsSlider = document.getElementById('nouislider');
    els.innerText = '$400/month'

    noUiSlider.create(this.clickPipsSlider, {
      range: {
        min: 0,
        max: 100000
      },
      tooltips: [{
        to: function (value) {
          return formatter.format(value);
        }
      }],
      start: [10000],
      step: 500,
      pips: { values: 6, density: 4 }
    }).on('change.one', function (event) {
      els.innerText = '$' + Math.ceil(event[0] * (40 / 1000)) + '/month';
    });
  }
  disconnect() {
    if(this.clickPipsSlider.noUiSlider) {
      this.clickPipsSlider.noUiSlider.destroy();
    }
  }
}
