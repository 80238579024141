import { Controller } from "stimulus";

import Highcharts from 'highcharts';

export default class extends Controller {
  static values = {
    chart: Object,
    filterBy: String
  }
  initialize() {    
    this.filterBy = 'earnings'
  }
  showDownloadData() {
    if(this.filterBy !== 'ep_download_count_daily') {
      this.filterBy = 'ep_download_count_daily';
      this.renderChart();
    }
  }
  showEarningsData() {
    if(this.filterBy !== 'earnings') {
      this.filterBy = 'earnings';
      this.renderChart();  
    }  
  }
  connect() {
   this.renderChart(); 
  }
  renderChart() {
    const data = JSON.parse(this.element.dataset.index).data;
    this.chart = document.getElementById('highchartContainer');
    const showEarnings = this.filterBy === 'earnings';
    Highcharts.chart(this.chart, {
      credits: false,
      chart: {
        type: 'column'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: _.pluck(data, 'd')
      },
      yAxis: {
        min: 0,
        title: {
          text: showEarnings ?'Earnings ($)' : 'Downloads'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:  `<td style="padding:0"><b>${showEarnings ? '$ {point.y:.1f}' : '{point.y:.0f}'} </b></td></tr>`,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [{
        showInLegend: false,
        color: '#7C6EE4',
        data:  _.pluck(data, this.filterBy)
    
      }]
    });
  }
  disconnect() {
    if(this.element) {
      console.log(this.element)
    }
  }
}
