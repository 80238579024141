import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "mainContent", "loaderContent"];

  connect() {
    const self = this;

    this.formTarget.onsubmit = (evt) => {
      self.mainContentTarget.classList.add("d-none");
      self.loaderContentTarget.classList.remove("d-none");
    }
  }
}
