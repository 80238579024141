import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select"]

  handleChange(evt) {
    const t = evt.currentTarget.value;
    const path = evt.currentTarget.dataset.url;
    Turbolinks.visit(this.updateQueryStringParameter(path, 't', t));
  }

  updateQueryStringParameter(uri, key, value) {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      return uri + separator + key + "=" + value;
    }
  }
}
