import { Controller } from "stimulus";
import bsCustomFileInput from "bs-custom-file-input";

export default class extends Controller {
  connect() {
    bsCustomFileInput.init()
  }

  onChange(evt) {
    evt.currentTarget.classList.remove("is-invalid")
  }
}
