import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["earningsInput", "earningsText"];

  connect() {
    this.onEarningsInputKeyUp();
  }

  onEarningsInputKeyUp(evt) {
    this.earningsInputTarget.value = this.earningsInputTarget.value.replace(/\D/g, "");

    const estimatedEarnings = this.estimatedEarnings(this.earningsInputTarget.value);
    const els = this.element.getElementsByClassName("js-earnings-text");
    for (let idx = 0; idx < els.length; idx++) {
      els[idx].innerText = "$" + estimatedEarnings;
    }
  }

  onPodcastClick(evt) {
    const downloads = parseInt(this.earningsInputTarget.value) || 0;
    if (downloads <= 0) {
      alert("Please input your estimated downloads");
      evt.preventDefault();
    }

    const path = evt.currentTarget.href;
    const params = new URLSearchParams(path);
    params.set('downloads', downloads);

    evt.currentTarget.href = decodeURIComponent(params.toString());
  }

  estimatedEarnings(listens) {
    return Math.ceil(listens * (40 / 1000))
  }
}
